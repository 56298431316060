
const countries = [
  {
    "name": "Afghanistan",
    "country_code": "AF",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Albania",
    "country_code": "AL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Algeria",
    "country_code": "DZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Andorra",
    "country_code": "AD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Angola",
    "country_code": "AO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Antigua and Barbuda",
    "country_code": "AG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Argentina",
    "country_code": "AR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Armenia",
    "country_code": "AM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Australia",
    "country_code": "AU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Austria",
    "country_code": "AT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Azerbajan",
    "country_code": "AZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bahamas",
    "country_code": "BS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bahrain",
    "country_code": "BH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bangladesh",
    "country_code": "BD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Barbados",
    "country_code": "BB",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Belarus",
    "country_code": "BY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Belgium",
    "country_code": "BE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Belize",
    "country_code": "BZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Benin",
    "country_code": "BJ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bhutan",
    "country_code": "BT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bolivia (Plurination State of)",
    "country_code": "BO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bosnia and Herzegovina",
    "country_code": "BA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Botswania",
    "country_code": "BW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Brazil",
    "country_code": "BR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Brunei Darussalam",
    "country_code": "BN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Bulgaria",
    "country_code": "BG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Burkina Faso",
    "country_code": "BF",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Burundi",
    "country_code": "BI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cabo Verde",
    "country_code": "CV",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cambodia",
    "country_code": "KH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cameroon",
    "country_code": "CM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Canada",
    "country_code": "CA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Central African Republic",
    "country_code": "CF",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Chad",
    "country_code": "TD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Chile",
    "country_code": "CL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "China",
    "country_code": "CN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Colombia",
    "country_code": "CO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Comoros",
    "country_code": "KM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Congo",
    "country_code": "CG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Costa Rica",
    "country_code": "CR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cote D'Ivoire",
    "country_code": "CI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Croatia",
    "country_code": "HR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cuba",
    "country_code": "CU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Cyprus",
    "country_code": "CY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Czech Republic",
    "country_code": "CZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Democratic People's Republic of Korea",
    "country_code": "KP",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Democratic Republic of the Congo",
    "country_code": "CD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Denmark",
    "country_code": "DK",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Djibouti",
    "country_code": "DJ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Dominica",
    "country_code": "DM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Dominican Republic",
    "country_code": "DO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Ecuador",
    "country_code": "EC",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Egypt",
    "country_code": "EG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "El Salvador",
    "country_code": "SV",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Equatorial Guinea",
    "country_code": "GQ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Eritrea",
    "country_code": "ER",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Estonia",
    "country_code": "EE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Eswatini",
    "country_code": "SZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Ethiopia",
    "country_code": "ET",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Fiji",
    "country_code": "FJ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Finland",
    "country_code": "FI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "France",
    "country_code": "FR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Gabon",
    "country_code": "GA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Gambia (Republic of The)",
    "country_code": "GM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Georgia",
    "country_code": "GE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Germany",
    "country_code": "DE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Ghana",
    "country_code": "GH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Greece",
    "country_code": "GR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Grenada",
    "country_code": "GD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Guatemala",
    "country_code": "GT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Guinea",
    "country_code": "GN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Guinea Bissau",
    "country_code": "GW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Guyana",
    "country_code": "GY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Haiti",
    "country_code": "HT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Honduras",
    "country_code": "HN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Hungary",
    "country_code": "HU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Iceland",
    "country_code": "IS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "India",
    "country_code": "IN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Indonesia",
    "country_code": "ID",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Iran (Islamic Republic of)",
    "country_code": "IR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Iraq",
    "country_code": "IQ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Ireland",
    "country_code": "IE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Israel",
    "country_code": "IL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Italy",
    "country_code": "IT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Jamaica",
    "country_code": "JM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Japan",
    "country_code": "JP",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Jordan",
    "country_code": "JO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Kazakhstan",
    "country_code": "KZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Kenya",
    "country_code": "KE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Kiribati",
    "country_code": "KI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Kuwait",
    "country_code": "KW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Kyrgyzstan",
    "country_code": "KG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Lao People's Democratic Republic",
    "country_code": "LA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Latvia",
    "country_code": "LV",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Lebanon",
    "country_code": "LB",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Lesotho",
    "country_code": "LS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Liberia",
    "country_code": "LR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Libya",
    "country_code": "LY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Liechtenstein",
    "country_code": "LI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Lithuania",
    "country_code": "LT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Luxembourg",
    "country_code": "LU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Madagascar",
    "country_code": "MG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Malawi",
    "country_code": "MW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Malaysia",
    "country_code": "MY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Maldives",
    "country_code": "MV",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mali",
    "country_code": "ML",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Malta",
    "country_code": "MT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Marshall Islands",
    "country_code": "MH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mauritania",
    "country_code": "MR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mauritius",
    "country_code": "MU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mexico",
    "country_code": "MX",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Micronesia (Fedeated States of)",
    "country_code": "FM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Monaco",
    "country_code": "MC",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mongolia",
    "country_code": "MN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Montenegro",
    "country_code": "ME",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Morocco",
    "country_code": "MA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Mozambique",
    "country_code": "MZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Myanmar",
    "country_code": "MM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Namibia",
    "country_code": "NA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Nauru",
    "country_code": "NR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Nepal",
    "country_code": "NP",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Netherlands",
    "country_code": "AN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "New Zealanad",
    "country_code": "NZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Nicaragua",
    "country_code": "NI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Niger",
    "country_code": "NE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Nigeria",
    "country_code": "NG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "North Macedonia",
    "country_code": "MK",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Norway",
    "country_code": "NO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Oman",
    "country_code": "OM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Pakistan",
    "country_code": "PK",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Palau",
    "country_code": "PW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Panama",
    "country_code": "PA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Paua New Guinea",
    "country_code": "PG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Paraguay",
    "country_code": "PY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Peru",
    "country_code": "PE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Philippines",
    "country_code": "PH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Poland",
    "country_code": "PL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Portugal",
    "country_code": "PT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Qatar",
    "country_code": "QA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Republic of Korea",
    "country_code": "KR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Republic of Moldova",
    "country_code": "MD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Romania",
    "country_code": "RO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Russian Federation",
    "country_code": "RU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Rwanda",
    "country_code": "RW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Saint Kitts and Nevis",
    "country_code": "KN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Saint Lucia",
    "country_code": "LC",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "country_code": "VC",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Samoa",
    "country_code": "WS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "San Marino",
    "country_code": "SM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Sao Tome and Principe",
    "country_code": "ST",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Saudi Arabia",
    "country_code": "SA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Senegal",
    "country_code": "SN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Serbia",
    "country_code": "RS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Seychelles",
    "country_code": "SC",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Sierra Leone",
    "country_code": "SL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Singapore",
    "country_code": "SG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Slovakia",
    "country_code": "SK",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Slovenia",
    "country_code": "SI",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Solomon Islands",
    "country_code": "SB",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Somalia",
    "country_code": "SO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "South Africa",
    "country_code": "ZA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "South Sudan",
    "country_code": "SS",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Spain",
    "country_code": "ES",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Sri Lanka",
    "country_code": "LK",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Sudan",
    "country_code": "SD",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Suriname",
    "country_code": "SR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Sweden",
    "country_code": "SE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Switzerland",
    "country_code": "CH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Syrian Arab Republic",
    "country_code": "SY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Tajikistan",
    "country_code": "TJ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Thailand",
    "country_code": "TH",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Timor-Leste",
    "country_code": "TL",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Togo",
    "country_code": "TG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Tonga",
    "country_code": "TO",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Trinidad and Tobago",
    "country_code": "TT",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Tunisia",
    "country_code": "TN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Turkey",
    "country_code": "TR",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Turkmenistan",
    "country_code": "TM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Tuvalu",
    "country_code": "TV",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Uganda",
    "country_code": "UG",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Ukraine",
    "country_code": "UA",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "United Arab Emirates",
    "country_code": "AE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "United Kingdom of Great Britain and Northern Ireland",
    "country_code": "GB",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "United Republic of Tanzania",
    "country_code": "TZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "United States of America",
    "country_code": "US",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Uruguay",
    "country_code": "UY",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Uzbekistan",
    "country_code": "UZ",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Vanuatu",
    "country_code": "VU",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Venezuela, Bolivarian Republic of",
    "country_code": "VE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Vietname",
    "country_code": "VN",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Yemen",
    "country_code": "YE",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Zambia",
    "country_code": "ZM",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  },
  {
    "name": "Zimbabwe",
    "country_code": "ZW",
    "country_flag_base": "",
    "presence": "",
    "stats_moderated": 0,
    "stats_single": 0,
    "stats_primary": 0,
    "stats_secondary": 0
  }
];

export { countries };