import React from 'react';

const Profile = () => {

    return (
        <div>
            Secretariat profile editing page (change password, change email)
        </div>
    )
}

export default Profile;